import React from 'react';

const PadlockLg = ({ className }) => (
  <svg className={className} viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M109.246 63.0301H4.3474C3.91821 63.0301 3.57031 63.3771 3.57031 63.8052V128.614C3.57031 129.042 3.91821 129.389 4.3474 129.389H109.246C109.675 129.389 110.023 129.042 110.023 128.614V63.8052C110.023 63.3771 109.675 63.0301 109.246 63.0301Z"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M33.0352 56.3939V46.9125C33.0352 33.8211 43.6698 23.2142 56.7954 23.2142C69.9211 23.2142 80.5557 33.8211 80.5557 46.9125V56.3939"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M56.7978 106.169C62.3125 106.169 66.7831 101.71 66.7831 96.2097C66.7831 90.7094 62.3125 86.2505 56.7978 86.2505C51.2831 86.2505 46.8125 90.7094 46.8125 96.2097C46.8125 101.71 51.2831 106.169 56.7978 106.169Z"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="103.429" cy="64.3634" rx="42.7222" ry="41.11" fill="#C2EDE0" />
    </g>
  </svg>
);

export default PadlockLg;
