import React from 'react';

const styles = `.st0{enable-background:new;} .st1{fill:#225E63;} .st2{fill:#FEFEFE;} .st3{fill:#2BBC5D;}`;

const PCICertifiedLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-label="PCI Certified Badge"
    viewBox="0 200 652 250"
    style={{ enableBackground: 'new 0 0 652 652' }}
    xmlSpace="preserve"
    {...props}
  >
    <title>PCI Certified Badge</title>
    <style type="text/css">{styles}</style>
    <g id="Page-1">
      <g transform="translate(0.000000, -8.000000)">
        <g className="st0">
          <path
            className="st1"
            d="M430.5,345.2l6,1.8c-0.4,1.7-1,3.1-1.9,4.2c-0.9,1.1-1.9,2-3.2,2.5c-1.3,0.6-2.9,0.9-4.9,0.9
         c-2.4,0-4.3-0.3-5.9-1c-1.5-0.7-2.8-1.9-3.9-3.7c-1.1-1.7-1.7-4-1.7-6.7c0-3.6,1-6.4,2.9-8.4c1.9-2,4.7-2.9,8.2-2.9
         c2.8,0,4.9,0.6,6.5,1.7c1.6,1.1,2.8,2.8,3.5,5.2l-6,1.3c-0.2-0.7-0.4-1.2-0.7-1.5c-0.4-0.5-0.8-0.9-1.4-1.2
         c-0.6-0.3-1.2-0.4-1.8-0.4c-1.5,0-2.7,0.6-3.5,1.9c-0.6,0.9-0.9,2.4-0.9,4.3c0,2.4,0.4,4.1,1.1,5c0.7,0.9,1.8,1.4,3.1,1.4
         c1.3,0,2.3-0.4,2.9-1.1C429.7,347.6,430.2,346.6,430.5,345.2z"
          />
          <path
            className="st1"
            d="M438.9,343.2c0-3.6,1-6.4,3-8.4c2-2,4.8-3,8.4-3c3.7,0,6.5,1,8.5,2.9c2,2,3,4.7,3,8.3c0,2.6-0.4,4.7-1.3,6.3
         c-0.9,1.6-2.1,2.9-3.8,3.8c-1.6,0.9-3.7,1.4-6.1,1.4c-2.5,0-4.5-0.4-6.2-1.2c-1.6-0.8-2.9-2-4-3.8S438.9,345.8,438.9,343.2z
          M445.7,343.2c0,2.2,0.4,3.8,1.2,4.8c0.8,1,1.9,1.5,3.4,1.5c1.5,0,2.6-0.5,3.4-1.4c0.8-1,1.2-2.7,1.2-5.1c0-2.1-0.4-3.6-1.3-4.5
         c-0.8-1-2-1.4-3.4-1.4c-1.4,0-2.5,0.5-3.3,1.5C446.1,339.4,445.7,341,445.7,343.2z"
          />
          <path
            className="st1"
            d="M465.3,332.2h8.9l3.4,13.4l3.4-13.4h8.9v22h-5.6v-16.8l-4.3,16.8h-5l-4.3-16.8v16.8h-5.6V332.2z"
          />
          <path
            className="st1"
            d="M494.3,332.2h11.3c2.5,0,4.3,0.6,5.5,1.8c1.2,1.2,1.8,2.8,1.8,5c0,2.2-0.7,4-2,5.2c-1.3,1.3-3.4,1.9-6.1,1.9
         h-3.7v8.2h-6.8V332.2z M501.1,341.6h1.7c1.3,0,2.2-0.2,2.8-0.7c0.5-0.5,0.8-1,0.8-1.7c0-0.7-0.2-1.3-0.7-1.8
         c-0.5-0.5-1.3-0.7-2.6-0.7h-1.9V341.6z"
          />
          <path className="st1" d="M516.5,332.2h6.8v16.6h10.6v5.4h-17.4V332.2z" />
          <path className="st1" d="M537.3,332.2h6.8v22h-6.8V332.2z" />
          <path
            className="st1"
            d="M562.5,350.5h-7.7l-1.1,3.6h-6.9l8.3-22h7.4l8.3,22h-7.1L562.5,350.5z M561.1,345.8l-2.4-7.9l-2.4,7.9H561.1
         z"
          />
          <path className="st1" d="M572.9,332.2h6.3l8.3,12.2v-12.2h6.4v22h-6.4l-8.2-12.1v12.1h-6.4V332.2z" />
          <path className="st1" d="M596.9,332.2h20.7v5.4h-6.9v16.6h-6.8v-16.6h-6.9V332.2z" />
        </g>
        <g id="Rectangle-3-_x2B_-Letters" transform="translate(0.000000, 8.500000)">
          <path
            id="Rectangle-3"
            className="st1"
            d="M348.7,357.2l19.7-6.2l-6.4-14.2C357.3,342.7,352.6,350.3,348.7,357.2z M342,292.3
         l-38.6-86l-282.6,16l78.3,213.6l150.7-47.5c-9.6-13.8-13.1-30.2-2-38.2c12.4-9,31.1,1.4,43,16.1
         C302.3,347.1,334.6,302.4,342,292.3z"
          />
          <g id="Letters" transform="translate(101.995804, 41.349650)">
            <path
              id="Oval-1"
              className="st2"
              d="M192.6,227.1c11.8,0,21.4-9,21.4-20.2c0-11.2-9.6-20.2-21.4-20.2c-11.8,0-21.4,9-21.4,20.2
           C171.2,218,180.8,227.1,192.6,227.1z M175.1,235.8h35v91.5h-35V235.8z"
            />
            <path
              id="Path-3"
              className="st2"
              d="M157.8,262.5c0.3,0.1,0.5,0,0.5-0.3v-23.2c0-0.3-0.2-0.6-0.5-0.8c0,0-4.6-3-18.6-3.8
           c-0.7-0.4-13-0.5-16.3,0c-51,4.1-52.9,41-52.9,42.6v9c0,1.1,0,38.8,52.9,42.1c5.2,0.4,15.3,0,16.3,0c12.2,0,20.6-3.7,20.6-3.7
           c0.3-0.1,0.5-0.4,0.5-0.7v-21.7c0-0.3-0.2-0.4-0.4-0.2c0,0-3.8,3-20.4,4.7c-4.7,0.5-7,0.3-8.7,0c-23.6-4-24.7-21.2-24.7-21.2
           c0-0.3-0.1-0.8-0.1-1v-6.6c0-0.3,0-0.8,0.1-1c0,0,1.6-18.5,24.7-20.5h8.7C149.6,257.5,157.8,262.5,157.8,262.5z"
            />
            <path
              id="Path-1"
              className="st2"
              d="M-27.2,327c0,0.3,0.2,0.5,0.5,0.5H7.4c0.3,0,0.5-0.2,0.5-0.5v-26.6c0-0.3,0.2-0.5,0.5-0.5
           c0,0,54.5,3.9,54.5-32.6c0-28.9-34.2-32-45.4-31.5c-0.2,0-44.2,0-44.2,0c-0.3,0-0.5,0.2-0.5,0.5L-27.2,327z M7.3,279.6v-23.9
           h8.4c0,0,12.1,0.5,13.1,8.8c0.2,0.6,0.2,4.7,0,4.9c-1.6,9.7-12.1,10.2-12.1,10.2L7.3,279.6z"
            />
          </g>
          <path
            id="Path-7"
            className="st3"
            d="M295.6,412.8c2.8,0,5,0,8.6-1.6c12.4-6.5,54.3-108.8,98.5-140.3c0.3-0.2,0.6-0.5,0.8-0.8
         c0.3-0.4,0.3-0.8,0.3-0.8s0-2.1-6.5-2.1c-39.3-1.1-80.2,81.4-101.7,114c-0.3,0.4-1.7,0-1.7,0s-14.4-17-26.9-23.5
         c-0.3-0.1-1.7-0.6-3.2-0.5c-1,0-6.8,1.2-9.5,4c-3.2,3.4-3.1,5.3-3.1,9.4c0,0.3,0.2,1.7,0.6,2.4c3.1,5.4,17,24.6,28.5,35.2
         C282,409.5,284.7,412.8,295.6,412.8z"
          />
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M414.3,248.5H447c6.5,0,11.7,0.9,15.6,2.6c4,1.8,7.3,4.3,9.8,7.5c2.6,3.3,4.5,7.1,5.6,11.4
         c1.2,4.3,1.8,8.9,1.8,13.8c0,7.6-0.9,13.5-2.6,17.7c-1.7,4.2-4.1,7.7-7.2,10.6c-3.1,2.8-6.4,4.7-9.9,5.7
         c-4.8,1.3-9.2,1.9-13.1,1.9h-32.7V248.5z M436.3,264.6v38.9h5.4c4.6,0,7.9-0.5,9.8-1.5s3.5-2.8,4.6-5.3c1.1-2.5,1.7-6.7,1.7-12.4
         c0-7.6-1.2-12.7-3.7-15.5c-2.5-2.8-6.5-4.2-12.3-4.2H436.3z"
          />
          <path
            className="st1"
            d="M485.9,296.2l21-1.3c0.5,3.4,1.4,6,2.8,7.8c2.3,2.9,5.5,4.3,9.7,4.3c3.1,0,5.6-0.7,7.3-2.2
         c1.7-1.5,2.6-3.2,2.6-5.1c0-1.8-0.8-3.5-2.4-5c-1.6-1.5-5.4-2.8-11.3-4.1c-9.7-2.2-16.5-5.1-20.7-8.7c-4.2-3.6-6.2-8.2-6.2-13.8
         c0-3.7,1.1-7.1,3.2-10.4c2.1-3.3,5.3-5.8,9.6-7.7c4.3-1.9,10.1-2.8,17.5-2.8c9.1,0,16.1,1.7,20.8,5.1c4.8,3.4,7.6,8.8,8.5,16.2
         l-20.8,1.2c-0.6-3.2-1.7-5.5-3.5-7c-1.8-1.5-4.2-2.2-7.3-2.2c-2.6,0-4.5,0.5-5.8,1.6c-1.3,1.1-1.9,2.4-1.9,4
         c0,1.1,0.5,2.2,1.6,3.1c1,0.9,3.5,1.8,7.4,2.6c9.6,2.1,16.5,4.2,20.7,6.3c4.2,2.1,7.2,4.8,9.1,7.9c1.9,3.1,2.8,6.7,2.8,10.6
         c0,4.6-1.3,8.8-3.8,12.6c-2.5,3.9-6.1,6.8-10.6,8.8c-4.5,2-10.3,3-17.2,3c-12.1,0-20.5-2.3-25.2-7
         C489.2,309.3,486.6,303.4,485.9,296.2z"
          />
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M556.2,296.2l21-1.3c0.5,3.4,1.4,6,2.8,7.8c2.3,2.9,5.5,4.3,9.7,4.3c3.1,0,5.6-0.7,7.3-2.2
         c1.7-1.5,2.6-3.2,2.6-5.1c0-1.8-0.8-3.5-2.4-5c-1.6-1.5-5.4-2.8-11.3-4.1c-9.7-2.2-16.5-5.1-20.7-8.7c-4.1-3.6-6.2-8.2-6.2-13.8
         c0-3.7,1.1-7.1,3.2-10.4c2.1-3.3,5.3-5.8,9.6-7.7c4.3-1.9,10.1-2.8,17.5-2.8c9.1,0,16.1,1.7,20.8,5.1c4.8,3.4,7.6,8.8,8.5,16.2
         l-20.8,1.2c-0.6-3.2-1.7-5.5-3.5-7c-1.8-1.5-4.2-2.2-7.3-2.2c-2.6,0-4.5,0.5-5.8,1.6c-1.3,1.1-1.9,2.4-1.9,4
         c0,1.1,0.5,2.2,1.6,3.1c1,0.9,3.5,1.8,7.4,2.6c9.6,2.1,16.5,4.2,20.7,6.3c4.2,2.1,7.2,4.8,9.1,7.9c1.9,3.1,2.8,6.7,2.8,10.6
         c0,4.6-1.3,8.8-3.8,12.6c-2.5,3.9-6.1,6.8-10.6,8.8c-4.5,2-10.3,3-17.2,3c-12.1,0-20.5-2.3-25.2-7
         C559.4,309.3,556.8,303.4,556.2,296.2z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PCICertifiedLogo;
