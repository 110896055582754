import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import PCICertifiedLogo from '../components/partner-logos/PCICertifiedLogo';
import PadlockLg from '../components/icons/PadlockLg';

const FaqPage = () => (
  <Layout>
    <Seo title="Subscribe Pro Security and Trust" />

    <Header graphic={undefined}>Security and Trust</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-2xl">
          <div className="float-right ml-12">
            <PadlockLg className="w-[18rem] h-auto" />
          </div>

          <h2>Data Portability</h2>
          <p>
            Subscribe Pro is fully committed to maintain data portability on behalf of our clients and their eCommerce customers.
            Please contact our support team with data portability requests and we will promptly, providing data exports to
            authorized parties.
          </p>

          <h2>Statement on Security</h2>
          <p>
            The Subscribe Pro service is developed and maintained with strong adherence to best practices in software development,
            testing, security, and reliability. The Subscribe Pro service is hosted in state of the art data centers managed by
            AWS. Amazon has many years of experience in designing, constructing, and operating large-scale data centers. This
            experience has been applied to ensure the highest level of security for your data. Subscribe Pro services are always
            architected using best practices and the latest standards, including using encryption at rest, encryption in transit,
            and secure key management.
          </p>

          <h3>Report a Potential Security Issue</h3>
          <p>
            If you have discovered or become aware of a potential security flaw or vulnerability, please contact our security team
            directly at <a href="mailto:security@subscribepro.com">security@subscribepro.com</a>
          </p>

          <h2>PCI Compliance</h2>
          <PCICertifiedLogo className="w-[16rem] h-auto" />
          <p>
            Subscribe Pro is a PCI Certified Level 1 Service Provider. We are compliant with the PCI (Payment Card Industry) DSS
            (Data Security Standard) version 3.2. Clients and business partners may contact us (support@subscribepro.com) for a
            copy of Subscribe Pro's current PCI Attestation of Compliance (AOC). Subscribe Pro Vault and Subscribe Pro Payments
            are designed to minimize compliance burden and risk for our clients, while providing maximum flexibility in terms of
            payments features.
          </p>

          <h2>GDPR and CCPA Compliance</h2>
          <div className="flex">
            <img
              src="/static-images/gdpr_approved_logo.png"
              alt="GDPR Approved"
              title="GDPR Approved"
              className="w-[8rem] h-auto my-0"
            />
            <img
              src="/static-images/ccpa_compliant.webp"
              alt="CCPA Compliant"
              title="CCPA Compliant"
              className="w-[12rem] h-auto my-0"
            />
          </div>
          <p>
            Subscribe Pro is committed to protecting the privacy and security of all data provided to our service.&nbsp;Subscribe
            Pro has entered into Data Protection Agreements with our vendors (sub-processors) to ensure that the privacy and
            security of our customer data is protected. We provide a{' '}
            <a href="/downloads/Subscribe-Pro-DPA-Template.pdf">
              Subscribe Pro - DPA
            </a>{' '}
            addendum to our Service License Agreement to assist our clients achieve GDPR and CCPA compliance.
          </p>
        </div>
      </Container>
    </section>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default FaqPage;
